// === AuthContext.js ===

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs'; 
import { SERVER_URL } from './constants';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //const [isOnboarded, setIsOnboarded] = useState(false);
    const [isOnboarded, setIsOnboarded] = useState('');         // 2025-02-24-I: if we default to false, we pop up too early and often
    const navigate = useNavigate();
    const location = useLocation();
    
    // useEffect(() => {
    //     //if (location.pathname === '/chat') {
    //     if (location.pathname.includes('chat')) {
    //         console.log("Checking user status for chat page...");
    //         checkUserStatus();
    //     }
    // }, [location]); 
    useEffect(() => {
        const requireAuthorization = location.pathname.includes('chat');
        checkUserStatus(requireAuthorization);
    }, [location]); 

    const checkLocalAuth = () => {
        console.log("In checkLocalAuth, cookies:", document.cookie);
        const hasSessionCookie = document.cookie.split(';').some((item) => item.trim().startsWith('sessionid='));   // http-only cookie is not visible to javascript...
        const hasLoginCookie = document.cookie.split(';').some((item) => item.trim().startsWith('logged_in='));     // ... so we have this one in parallel. 
        const hasAuthToken = localStorage.getItem('authToken');
        console.log("In checkLocalAuth, hasSessionCookie:", hasSessionCookie);
        console.log("In checkLocalAuth, hasAuthToken:", hasAuthToken);
        console.log("In checkLocalAuth, hasLoginCookie:", hasLoginCookie);
        return hasSessionCookie || hasAuthToken || hasLoginCookie;
    };
      
    const checkUserStatus = (requireAuthorization = false) => {
        console.log("Checking user status...");
        console.log("requireAuthorization: ", requireAuthorization);
        
        //console.log("Cookies:", document.cookie);
    
        // 2024-07-23-F    
        if (!checkLocalAuth()) {
            console.log("No local auth indicators found");
            setUser(null);
            setIsLoggedIn(false);
            if (requireAuthorization) {
                console.log("Authorization required, redirecting to /login...");
                navigate('/login');
            }
            return;
        }
        console.log("Local auth indicators found, verifying with server...");
        
        
        axios.post(SERVER_URL, qs.stringify({
            action: 'get_user_status'
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        })
        .then(response => {
            const data = response.data;
            console.log("User status data: ", data);

            console.log("data.user_id: ", data.user_id);
            console.log("data.user_is_logged_in: ", data.user_is_logged_in);
            console.log("data.email_address_is_confirmed: ", data.email_address_is_confirmed);
            console.log("data.waitlist_status_is_approved: ", data.waitlist_status_is_approved);
            
            // 2025-01-10-K
            console.log("data.is_onboarded: ", data.is_onboarded);

            // setIsLoggedIn(data.user_is_logged_in === 1 || data.user_is_logged_in === "1");

            // if(
            //     !( 
            //         (data.user_is_logged_in === 1) 
            //         || 
            //         (data.user_is_logged_in === "1") 
            //     )
            // ){

            const userIsLoggedIn = data.user_is_logged_in === 1 || data.user_is_logged_in === "1";
            setIsLoggedIn(userIsLoggedIn);
            setIsOnboarded(data.is_onboarded === 1 || data.is_onboarded === "1");  // 2025-01-10-L

            if (!userIsLoggedIn) {
                
                setUser(null);
                if (requireAuthorization) {
                    console.log("User not logged in, redirecting to /login...");
                    navigate('/login');
                }
                
            } else if(
                !( 
                    (data.email_address_is_confirmed === 1) 
                    || 
                    (data.email_address_is_confirmed === "1") 
                )
            ){
                //setUser(null);
                if (requireAuthorization) {
                    console.log("Email not confirmed, redirecting to /validate_email...");
                    navigate('/validate_email');
                }
            } else if(
                !( 
                    (data.waitlist_status_is_approved === 1) 
                    || 
                    (data.waitlist_status_is_approved === "1") 
                )
            ){
                if (requireAuthorization) {  
                    console.log("User not approved on waitlist, redirecting to /waitlist...");
                    navigate('/waitlist');
                }
            } else {
                console.log("User is fully authenticated and approved.");
                setUser({ userId: data.user_id });
                
                // 2024-07-24-G
                // Check if the current path is '/' and redirect to '/chat' if so
                if (location.pathname === '/') {
                    console.log("Redirecting authenticated user from '/' to '/chat'");
                    navigate('/chat');
                }                
            }
        })
        .catch(error => {
            console.error("Error checking user status:", error);
            setUser(null);
            setIsLoggedIn(false);
            if (requireAuthorization) {
                navigate('/login');
            }
        });
    };
    
    const setCookie = (name, value, days) => {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };    

    // 2024-09-10-G
    const handleGoogleLogin = (response) => {
        const { credential } = response;
        
        console.log("Inside handleGoogleLogin");
        console.log("google_id_token: ", credential);
        
        axios.post(SERVER_URL, qs.stringify({
            action: 'authenticate_user_with_google',
            google_id_token: credential
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        })
        .then(response => {
            const data = response.data;
            if (data.login_successful == 1) {

                console.log("Google login successful...");

                setUser({ userId: data.user_id });
                setIsLoggedIn(true);

                console.log("Setting logged_in cookie...");
                setCookie('logged_in', '1', 7);  // Set cookie for 7 days
                
                console.log("Redirecting to chat...");
                
                navigate('/chat');
            } else {
                console.error('Google login failed');
                // Handle login failure (e.g., show an error message)
            }
        })
        .catch(error => {
            console.error('Google login error:', error);
            // Handle login error (e.g., show an error message)
        });
    };

    const deleteCookie = (name) => {
        // Delete cookie for the current domain
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict`;
        
        // Delete cookie for potential subdomains
        // const domain = window.location.hostname;
        // const domainParts = domain.split('.');
        // if (domainParts.length > 2) {
        //     const topLevelDomain = domainParts.slice(-2).join('.');
        //     document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${topLevelDomain}; SameSite=Strict`;
        // }
    };

    const handleLogout = () => {
        // Call the server to invalidate the session
        console.log("Inside handleLogout...");
        axios.post(SERVER_URL, qs.stringify({
            action: 'log_out_user'
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        })
        .then(() => {
            
            console.log("Finished server call.");
            
            // maybe verify success from server.
            
            // Delete the 'sessionid' cookie
            //document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.yourdomain.com; Secure; SameSite=Strict';
            
            deleteCookie('sessionid');
            deleteCookie('logged_in');            
            
            // Clear any local storage items
            localStorage.clear();
            
            // Update the auth context
            setUser(null);
            setIsLoggedIn(false);
            
            // Redirect to home page
            navigate('/');
            
            // Optionally, show a logout success message
            // setShowSuccessMessage(true);
        })
        .catch(error => {
            console.error('Logout error:', error);
            // Handle logout error (e.g., show an error message to the user)
        });
    };

    return (
        <AuthContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn, checkUserStatus, handleLogout, handleGoogleLogin, isOnboarded }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);


































